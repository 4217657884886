import featured from "./featured-examples.toml";

export type FeaturedExample = {
  stem: string;
  tags: string[];
  title: string;
  description: string;
  imageUrl?: string;
};

export function getFeaturedExamples(tag: string): FeaturedExample[] {
  return (featured.examples as FeaturedExample[]).filter((example) =>
    example.tags.includes(tag),
  );
}

const defaultImageUrls = [
  "https://modal-cdn.com/featured-example-default-5.webp",
  "https://modal-cdn.com/tmp405jyxau_3f1b4c28.webp",
  "https://modal-cdn.com/tmpuc263i_r_93dd71be.webp",
  "https://modal-cdn.com/tmpg8w4kd4s_a3f3590a.webp",
  "https://modal-cdn.com/tmpfsj_3fzt_8575b631.webp",
  "https://modal-cdn.com/tmp0w8m2kax_7d210712.webp",
  "https://modal-cdn.com/tmpkk1hgjur_3e1e3327.webp",
  "https://modal-cdn.com/tmp5l3jdxls_5a2ed3e0.webp",
  "https://modal-cdn.com/tmp6xtl1b66_14797487.webp",
  "https://modal-cdn.com/tmp13y9bt03_77749172.webp",
  "https://modal-cdn.com/tmp2kc5uijf_0c7c93e0.webp",
  "https://modal-cdn.com/tmpzwz4uw6m_76c7a39c.webp",
  "https://modal-cdn.com/tmpq4nxmb48_0d47acee.webp",
  "https://modal-cdn.com/tmpcjr5qxv5_7f77e9c7.webp",
  "https://modal-cdn.com/tmp192qvps9_15735517.webp",
  "https://modal-cdn.com/tmp5rgn5gv1_5860bcab.webp",
  "https://modal-cdn.com/tmppragymr2_9a5e4984.webp",
  "https://modal-cdn.com/tmpm0oyejsp_ae5a84be.webp",
  "https://modal-cdn.com/tmpm8jlb2uy_8431c8b9.webp",
  "https://modal-cdn.com/tmp3epgmjsf_df8ef3bb.webp",
  "https://modal-cdn.com/tmpsbos_uiq_004df25a.webp",
  "https://modal-cdn.com/tmp2u72nqjy_c5bf328e.webp",
];

export function getExampleImage(featuredExample: FeaturedExample): string {
  const stemCode = featuredExample.stem.charCodeAt(
    Math.floor((featuredExample.stem.length - 1) / 2),
  );
  const titleCode = featuredExample.title
    ? featuredExample.title.charCodeAt(featuredExample.title.length - 1)
    : 0;
  const imageCode = (stemCode + titleCode) % defaultImageUrls.length;
  return featuredExample.imageUrl || defaultImageUrls[imageCode];
}
